<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="10">
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getCompanies()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="10" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="info" block @click="createCompany()">{{ $t('create_company') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <CBadge slot="root" slot-scope="props" :color="$_.find(rootOptions, option => { return option.value === props.row.root }).color">
              {{ $_.find(rootOptions, option => { return option.value === props.row.root }).label }}
            </CBadge>
            <CBadge slot="apiEnabled" slot-scope="props" :color="$_.find(apiEnabledOptions, option => { return option.value === props.row.apiEnabled }).color">
              {{ $_.find(apiEnabledOptions, option => { return option.value === props.row.apiEnabled }).label }}
            </CBadge>
            <CBadge slot="enabled" slot-scope="props" :color="$_.find(enabledOptions, option => { return option.value === props.row.enabled }).color">
              {{ $_.find(enabledOptions, option => { return option.value === props.row.enabled }).label }}
            </CBadge>
            <font slot="createdAt" slot-scope="props">
              {{ $moment(props.row.createdAt).format('YYYY-MM-DD HH:mm:ss') }}
            </font>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="info" v-bind="{ variant: 'outline' }" @click="() => customSettingsModal = { data: props.row, modal: true }">{{ $t('device_customer_settings') }}</CButton>
                <CButton color="warning" v-bind="{ variant: 'outline' }" @click="editCompany(props.row)">{{ $t('edit') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <CModal
        :title="$t('create_company')"
        color="info"
        :show.sync="createModal"
      >
        <CRow>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('name')"
              :placeholder="$t('name')"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              v-model="createData.name"
              :isValid="valid_createData_name"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <CInput
              :label="'APP ID'"
              :placeholder="'APP ID'"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              v-model="createData.appid"
              :isValid="valid_createData_appid"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <CSelect
              :label="'ROOT'"
              :placeholder="'ROOT'"
              :options="rootOptions"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              :value.sync="createData.root"
              :isValid="valid_createData_root"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <CSelect
              :label="'API'"
              :placeholder="'API'"
              :options="apiEnabledOptions"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              :value.sync="createData.apiEnabled"
              :isValid="valid_createData_apiEnabled"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <CSelect
              :label="$t('enable')"
              :placeholder="$t('enable')"
              :options="enabledOptions"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              :value.sync="createData.enabled"
              :isValid="valid_createData_enabled"
            />
          </CCol>
        </CRow>
        <CButton slot="footer" color="primary" block @click="storeCompany()">{{ $t('save') }}</CButton>
      </CModal>

      <CModal
        :title="$t('edit_company')"
        color="warning"
        :show.sync="editModal"
      >
        <CRow>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('name')"
              :placeholder="$t('name')"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              v-model="editData.name"
              :isValid="valid_editData_name"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <CInput
              :label="'APP ID'"
              :placeholder="'APP ID'"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              v-model="editData.appid"
              :isValid="valid_editData_appid"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <CSelect
              :label="'ROOT'"
              :placeholder="'ROOT'"
              :options="rootOptions"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              :value.sync="editData.root"
              :isValid="valid_editData_root"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <CSelect
              :label="'API'"
              :placeholder="'API'"
              :options="apiEnabledOptions"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              :value.sync="editData.apiEnabled"
              :isValid="valid_editData_apiEnabled"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <CSelect
              :label="$t('enable')"
              :placeholder="$t('enable')"
              :options="enabledOptions"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              :value.sync="editData.enabled"
              :isValid="valid_editData_enabled"
            />
          </CCol>
        </CRow>
        <CButton slot="footer" color="primary" block @click="updateCompany()">{{ $t('save') }}</CButton>
      </CModal>

      <CustomSettingsModal :data="customSettingsModal.data" :show="customSettingsModal.modal" @showUpdate="show => customSettingsModal.modal = show" />

    </CCol>
  </CRow>
</template>

<script>
import CustomSettingsModal from '../organization/CustomSettingsModal'

export default {
  name: 'company',
  components: {
    CustomSettingsModal
  },
  data () {
    return {
      columns: [ 'name', 'appid', 'root', 'apiEnabled', 'enabled', 'createdAt', 'action' ],
      data: [],
      options: {
        headings: {
          name: this.$t('name'),
          appid: 'APP ID',
          root: 'ROOT',
          apiEnabled: 'API',
          enabled: this.$t('enable'),
          createdAt: this.$t('create_time'),
          action: this.$t('action')
        },
        sortable: [ 'name', 'appid', 'root', 'apiEnabled', 'enabled', 'createdAt' ],
        filterable: [ 'name', 'appid', 'root', 'apiEnabled', 'enabled', 'createdAt' ]
      },
      rootOptions: [
        { value: null, label: this.$t('please_select_a') + 'ROOT ' + this.$t('enable') + '...' },
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      apiEnabledOptions: [
        { value: null, label: this.$t('please_select_a') + 'API ' + this.$t('enable') + '...' },
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      enabledOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('enable') + '...' },
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: { companyId: null, email: '', enabled: null },
      createData: {},
      createModal: false,
      editData: {},
      editModal: false,
      customSettingsModal: { data: {}, modal: false },
    }
  },
  computed: {
    valid_createData_name () {
      return this.createData.name ? true : false
    },
    valid_createData_appid () {
      return this.createData.appid ? true : false
    },
    valid_createData_root () {
      return this.createData.root === 0 || this.createData.root === 1 ? true : false
    },
    valid_createData_apiEnabled () {
      return this.createData.apiEnabled === 0 || this.createData.apiEnabled === 1 ? true : false
    },
    valid_createData_enabled () {
      return this.createData.enabled === 0 || this.createData.enabled === 1 ? true : false
    },
    valid_editData_name () {
      return this.editData.name ? true : false
    },
    valid_editData_appid () {
      return this.editData.appid ? true : false
    },
    valid_editData_root () {
      return this.editData.root === 0 || this.editData.root === 1 ? true : false
    },
    valid_editData_apiEnabled () {
      return this.editData.apiEnabled === 0 || this.editData.apiEnabled === 1 ? true : false
    },
    valid_editData_enabled () {
      return this.editData.enabled === 0 || this.editData.enabled === 1 ? true : false
    },
    valid_storeCompany () {
      return this.valid_createData_name && this.valid_createData_appid && this.valid_createData_root && this.valid_createData_apiEnabled && this.valid_createData_enabled
    },
    valid_updateCompany () {
      return this.valid_editData_name && this.valid_editData_appid && this.valid_editData_root && this.valid_editData_apiEnabled && this.valid_editData_enabled
    }
  },
  mounted: function () {
  },
  methods: {
    getCompanies () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getCompanies').then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    createCompany () {
      this.createData = { name: '', appid: '', root: 0, apiEnabled: 0, enabled: 1 }
      this.createModal = true
    },
    storeCompany () {
      if (this.valid_storeCompany) {
        const loader = this.$loading.show()
        this.$store.dispatch('storeCompany', this.$_.clone(this.createData)).then(res => {
          this.getCompanies()
          this.createModal = false
          this.$swal('Success', this.$t('created_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
    editCompany (prop) {
      this.editData = { id: prop.id, name: prop.name, appid: prop.appid, root: prop.root, apiEnabled: prop.apiEnabled, enabled: prop.enabled }
      this.editModal = true
    },
    updateCompany () {
      if (this.valid_updateCompany) {
        const loader = this.$loading.show()
        this.$store.dispatch('updateCompany', this.$_.clone(this.editData)).then(res => {
          this.getCompanies()
          this.editModal = false
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    }
  }
}
</script>
