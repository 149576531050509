<template>
  <CModal
    :title="$t('device_customer_settings')"
    size="lg"
    color="info"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('company') }}
          </label>
          <div class="col-sm-9 d-flex align-items-center">
            {{ modal.data.name }}
          </div>
        </div>
      </CCol>
    </CRow>
    <hr>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('main_ip')"
          :placeholder="$t('main_ip')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="editData.settings.IP1"
          :disabled="!selected.IP1"
        >
          <div class="input-group-text bg-white" slot="prepend">
            <input type="checkbox" v-model="selected.IP1">
          </div>
        </CInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('alternate_ip')"
          :placeholder="$t('alternate_ip')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="editData.settings.IP2"
          :disabled="!selected.IP2"
        >
          <div class="input-group-text bg-white" slot="prepend">
            <input type="checkbox" v-model="selected.IP2">
          </div>
        </CInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('port')"
          :placeholder="$t('port')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="editData.settings.port"
          :disabled="!selected.port"
        >
          <div class="input-group-text bg-white" slot="prepend">
            <input type="checkbox" v-model="selected.port">
          </div>
        </CInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('geomagnetic_X') + '0'"
          :placeholder="$t('geomagnetic_X') + '0'"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="editData.settings.X0"
          :disabled="!selected.X0"
        >
          <div class="input-group-text bg-white" slot="prepend">
            <input type="checkbox" v-model="selected.X0">
          </div>
          <CButton color="info" size="sm" slot="append" @click="() => parsedDataModal = { data: { devid: $_.isArray(modal.data.devid) ? modal.data.devid[0] : modal.data.devid }, modal: true }" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-parsedData' }) && editData.devid && (!$_.isArray(editData.devid) || editData.devid.length === 1)">{{ $t('parsed_data') }}</CButton>
        </CInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('geomagnetic_Y') + '0'"
          :placeholder="$t('geomagnetic_Y') + '0'"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="editData.settings.Y0"
          :disabled="!selected.Y0"
        >
          <div class="input-group-text bg-white" slot="prepend">
            <input type="checkbox" v-model="selected.Y0">
          </div>
        </CInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('geomagnetic_Z') + '0'"
          :placeholder="$t('geomagnetic_Z') + '0'"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="editData.settings.Z0"
          :disabled="!selected.Z0"
        >
          <div class="input-group-text bg-white" slot="prepend">
            <input type="checkbox" v-model="selected.Z0">
          </div>
        </CInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('geomagnetic_infrared_ratio')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          type="range"
          v-model="editData.settings.rateMag"
          :min="0"
          :max="100"
          :description="$t('geomagnetic_infrared_ratio') + (editData.settings && /^[0-9]+$/.test(editData.settings.rateMag) ? ' ' + editData.settings.rateMag + ' : ' + (100 - editData.settings.rateMag) : '')"
          :disabled="!selected.rateMag"
        >
          <div class="input-group-text bg-white" slot="prepend">
            <input type="checkbox" v-model="selected.rateMag" @change="selected.rateIR = selected.rateMag">
          </div>
        </CInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('geomagnetic_change_threshold')"
          :placeholder="$t('geomagnetic_change_threshold')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="editData.settings.changeThresholdMag"
          :disabled="!selected.changeThresholdMag"
        >
          <div class="input-group-text bg-white" slot="prepend">
            <input type="checkbox" v-model="selected.changeThresholdMag">
          </div>
        </CInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('infrared_change_threshold')"
          :placeholder="$t('infrared_change_threshold')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="editData.settings.changeThresholdIR"
          :disabled="!selected.changeThresholdIR"
        >
          <div class="input-group-text bg-white" slot="prepend">
            <input type="checkbox" v-model="selected.changeThresholdIR">
          </div>
        </CInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('geomagnetic_trigger_threshold')"
          :placeholder="$t('geomagnetic_trigger_threshold')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="editData.settings.triggerThresholdMag"
          :disabled="!selected.triggerThresholdMag"
        >
          <div class="input-group-text bg-white" slot="prepend">
            <input type="checkbox" v-model="selected.triggerThresholdMag">
          </div>
        </CInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('geomagnetic_stability_threshold')"
          :placeholder="$t('geomagnetic_stability_threshold')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="editData.settings.stableThresholdMag"
          :disabled="!selected.stableThresholdMag"
        >
          <div class="input-group-text bg-white" slot="prepend">
            <input type="checkbox" v-model="selected.stableThresholdMag">
          </div>
        </CInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('geomagnetic_stability_time')"
          :placeholder="$t('geomagnetic_stability_time')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="editData.settings.stableMagTime"
          :disabled="!selected.stableMagTime"
        >
          <div class="input-group-text bg-white" slot="prepend">
            <input type="checkbox" v-model="selected.stableMagTime">
          </div>
        </CInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('heartbeat_intervals')"
          :placeholder="$t('heartbeat_intervals')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="editData.settings.heartbeatIntervals"
          :disabled="!selected.heartbeatIntervals"
        >
          <div class="input-group-text bg-white" slot="prepend">
            <input type="checkbox" v-model="selected.heartbeatIntervals">
          </div>
        </CInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('sensor_four_dimensional_vector_change_threshold')"
          :placeholder="$t('sensor_four_dimensional_vector_change_threshold')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="editData.settings.changeThresholdFDV"
          :disabled="!selected.changeThresholdFDV"
        >
          <div class="input-group-text bg-white" slot="prepend">
            <input type="checkbox" v-model="selected.changeThresholdFDV">
          </div>
        </CInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CSelect
          :label="$t('initialization_option')"
          :placeholder="$t('initialization_option')"
          :options="initializationOptionOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="editData.settings.initializationOption"
          :disabled="!selected.initializationOption"
        >
          <div class="input-group-text bg-white" slot="prepend">
            <input type="checkbox" v-model="selected.initializationOption">
          </div>
        </CSelect>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="'IP3' + $t('heartbeat_intervals')"
          :placeholder="'IP3' + $t('heartbeat_intervals')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="editData.settings.IP3HeartbeatIntervals"
          :disabled="!selected.IP3HeartbeatIntervals"
        >
          <div class="input-group-text bg-white" slot="prepend">
            <input type="checkbox" v-model="selected.IP3HeartbeatIntervals">
          </div>
        </CInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CSelect
          :label="$t('imei_option')"
          :placeholder="$t('imei_option')"
          :options="imeiOptionOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="editData.settings.imeiOption"
          :disabled="!selected.imeiOption"
        >
          <div class="input-group-text bg-white" slot="prepend">
            <input type="checkbox" v-model="selected.imeiOption">
          </div>
        </CSelect>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          label="T3412"
          placeholder="T3412"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="editData.settings.T3412"
          :disabled="!selected.T3412"
        >
          <div class="input-group-text bg-white" slot="prepend">
            <input type="checkbox" v-model="selected.T3412">
          </div>
        </CInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          label="T3324"
          placeholder="T3324"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="editData.settings.T3324"
          :disabled="!selected.T3324"
        >
          <div class="input-group-text bg-white" slot="prepend">
            <input type="checkbox" v-model="selected.T3324">
          </div>
        </CInput>
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" class="px-4" :disabled="!valid_editData_companyId" @click="updateCustomSettings()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'CustomSettingsModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      initializationOptionOptions: [
        { value: 169, label: this.$t('enable') },
        { value: 0, label: this.$t('disable') }
      ],
      imeiOptionOptions: [
        { value: 1, label: this.$t('enable') },
        { value: 0, label: this.$t('disable') }
      ],
      selected: {},
      parsedDataModal: { data: {}, modal: false },
      editData: { devid: '', settings: {} }
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.selected = {}
        this.editData = { companyId: this.modal.data.id, settings: {} }
        this.getCustomSettings()
      }
    }
  },
  computed: {
    valid_editData_companyId () {
      return this.editData.companyId ? true : false
    },
    valid_editData_settings () {
      const settings = this.$_.pick(this.editData.settings, (v, k) => this.selected[k])
      return this.$_.keys(settings).length === this.$_.keys(this.$_.pick(settings, v => v === 0 || v)).length
    },
    valid_updateCustomSettings () {
      return this.valid_editData_companyId && this.valid_editData_settings
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    getCustomSettings () {
      if (this.valid_editData_companyId) {
        const loader = this.$loading.show()
        this.$store.dispatch('getCustomSettings', this.editData).then(res => {
          const customSettings = this.$_.object(this.$_.pluck(res, 'paramKey'), this.$_.pluck(res, 'paramVal'))
          this.editData.settings = {
            IP1: customSettings.IP1 || '',
            IP2: customSettings.IP2 || '',
            port: customSettings.port || '',
            X0: customSettings.X0 || 0,
            Y0: customSettings.Y0 || 0,
            Z0: customSettings.Z0 || 0,
            rateMag: customSettings.rateMag || 55,
            rateIR: customSettings.rateIR || 45,
            changeThresholdMag: customSettings.changeThresholdMag || '',
            changeThresholdIR: customSettings.changeThresholdIR || '',
            heartbeatIntervals: customSettings.heartbeatIntervals || '',
            triggerThresholdMag: customSettings.triggerThresholdMag || '',
            stableThresholdMag: customSettings.stableThresholdMag || '',
            stableMagTime: customSettings.stableMagTime || '',
            initializationOption: parseInt(customSettings.initializationOption) || 0,
            T3412: customSettings.T3412 || '',
            T3324: customSettings.T3324 || '',
            changeThresholdFDV: customSettings.changeThresholdFDV || '',
            IP3HeartbeatIntervals: customSettings.IP3HeartbeatIntervals || '',
            imeiOption: parseInt(customSettings.imeiOption) || 0,
          }
          this.selected = this.$_.object(this.$_.pluck(res, 'paramKey'), this.$_.map(res, () => true))
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      }
    },
    updateCustomSettings () {
      if (this.valid_updateCustomSettings) {
        const loader = this.$loading.show()
        this.editData.settings.rateIR = 100 - this.editData.settings.rateMag
        this.$store.dispatch('updateCustomSettings', { companyId: this.editData.companyId, settings: this.$_.pick(this.editData.settings, (v, k) => this.selected[k]) }).then(res => {
          this.showUpdate(false)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    }
  },
  beforeDestroy: function () {
  },
}
</script>
